.title-container{
    position: relative;
}
.title-container .sub-heading{
    background-color: #043716;
    color:white;
    position: absolute;
    top: 21px; 
    right: 0; 
    font-weight: bold;
    font-size: 12px;
    padding: 2px;

}