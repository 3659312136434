.carousel-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.carousel-slide {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px; /* Adjust the height as needed */
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.carousel-background {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.5) blur(5px); /* Adjust filter values as needed */
  z-index: -1;
}


.img-parent {
  position: relative;
  display: inline-block;
}
.img-parent img {
  width: 60%; /* Adjust the size of the front image */
  height: auto;
  max-width: 100%;
  max-height: 60%; /* Adjust the height of the clear image */
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border: 2px solid #fff;
  transition: transform 0.5s ease-in-out;
}


.carousel-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.carousel-content img {
  width: 60%; /* Adjust the size of the front image */
  height: auto;
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border: 2px solid #fff; /* Add border around clear image */
  transition: transform 0.5s ease-in-out;
}


.carousel-content img:hover {
  transform: scale(1.1);
}

/* Example: Animation */
.slick-fade {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.slick-fade.slick-active {
  opacity: 1;
}

.carousel-slide:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}

.carousel-slide:hover .carousel-content img {
  transform: scale(1.1);
}

.carousel-slide.slick-center {
  transform: scale(0.95);
}

.package-title{

  position: absolute;
  bottom:65px;
  background-color: rgba(255, 255, 255, 0.5); /* Transparent white background */
  padding: 2px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
}
.package-title h5{
  color: #043716; /* Blue text color */
            font-style: italic;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Text shadow */

}



@media screen and (max-width:768px){
  .carousel-content img {
    width: 60%; /* Adjust the size of the front image */
    height: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border: 2px solid #fff; /* Add border around clear image */
    transition: transform 0.5s ease-in-out;
  }

  .package-title{

    position: absolute;
    bottom:110px;
    background-color: rgba(255, 255, 255, 0.5); /* Transparent white background */
      padding: 2px;
      border-radius: 10px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
  }
  
  
}


@media screen and (max-width:576px){
  .carousel-content img {
    width: 100%; /* Adjust the size of the front image */
    height: auto;
    max-height: 100%;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border: 2px solid #fff; /* Add border around clear image */
    transition: transform 0.5s ease-in-out;

  }  
  
 

  
  
}
.custom-div {
  background-color: rgba(255, 255, 255, 0.5); /* Transparent white background */
  padding: 20px;
  border-radius: 10px;
}
