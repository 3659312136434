.back-to-top {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px 15px;
    background-color: #02531a;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .back-to-top.visible {
    display: block;
  }
  