/* @media screen and (max-width:768px){
    .customimg {
      width: 25%;
      height: auto;
   
    }
  }
  
  @media screen and (max-width:575px){
    .customimg {
      width: 25%;
      height: auto;
  
    }  
}   */
/* .cusBg{

  background-color:rgba(0, 102, 0, 0.1);
} */
.customimg{
  width: 90% !important;
}

  @media (max-width: 576px) {
    .customimg {
      width: 70px; 
    }

    .d-flex {
      justify-content: center;
    }
  }

  @media (min-width: 577px) and (max-width: 992px) {
    .customimg {
      width: 80px; 
    }
  }

